@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import 'admin-theme.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a, a:hover {
  color: #45706f;
}

.bg-light-green {
  background-color: #dce7e8 !important;
}

.btn-primary {
  border-color: #45706f !important;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #193433 !important;
  border-color: #193433 !important;
}


.bg-warning {
  background-color: #f1c150 !important;
}

.bg-danger {
  background-color: #f86f7a !important;
}

.bg-success {
  background-color: #6cc1ac !important;
}

.toast.bg-success {
  background-color: #6cc1ac !important;
}

.fc-button-active {
  background-color: #193433 !important;
}

.bg-info {
  background-color: #8ad3dd !important;
}

.card-info > .card-header {
  background-color: #45706f !important;
}

.page-item > .page-link {
  color: #45706f;
}

.page-item.active > .page-link {
  color: #ffffff;
  background-color: #45706f;
  border-color: #45706f;
}


.sidebar-dark-primary {
  background-color: #45706f;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active {
  color: #45706f;
  background-color: #ffffff;
}

button.fc-dataRangeButton-button.fc-button.fc-button-primary::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  content: "\f133";
  font-size: 1em;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 480px) {
  div[id^="cdk-overlay-"] {
    left: 35px !important;
  }
}

@keyframes blinking {
  0% {
    background-color: white;
  }
  100% {
    background-color: #ffc107;
  }
}

/* (B) ATTACH WARNING KEYFRAMES TO CONTAINER */
.blink {
  animation: blinking 1s infinite;
}
