@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body {
  height: 100% !important;
}

app-root {
  display: block;
  height: 100%;
}

app-admin {
  display: block;
  height: 100%;
}

.main-sidenav {
  font-size: 25px;
}

.wrapper {
  height: 100%;
}

.login-wrapper {
  width: 60vw;
  max-width: 700px;
}

.login-form {
  width: 60vw;
  max-width: 300px;
}

.input-login {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #0009 !important;
}

.input-login-label {
  color: #0009;
  font-size: 16px;
  font-weight: 400 !important;
}

.input-login:focus {
  outline: none 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-bottom: 2px solid #00695c !important;
  transform: scaleX(1) !important;
}

.btn-login {
  color: #323232 !important;
  border: none !important;
  border-radius: 3px !important;
  box-shadow: 0 3px 5px -1px #0003, 0 5px 8px #00000024, 0 1px 14px #0000001f !important;
  font-size: 15px !important;
}

.btn-login:hover {
  background: rgba(0, 0, 0, 0.07) !important;
}

.navbar {
  background-color: #00695c;
}

.main-header a {
  color: #ffffff !important;
}

.mat-toolbar {
  background-color: #00695c !important;
  color: #ffffff !important;
  box-shadow: 0 0 10px 2px #0003, 0 0 10px #0000003d;
  height: 50px !important;
  font-weight: 300 !important;
  font-size: 21px !important;
}

.left-menu fa-icon {
  font-size: 24px !important;
}

.left-menu a {
  text-transform: uppercase;
  font-size: 14px !important;

}

.active span {
  color: #00695c !important;
}

.main-sidenav {
  min-width: 300px;
}

.left-menu a span {
  font-size: 14px !important;
  font-weight: 300;
}

.btn-success {
  background-color: #00695c !important;
}

.btn-primary {
  background-color: #00695c !important;
}

.fc-button-primary {
  background-color: #00695c !important;
}

.menu-spacer {
  flex: 1 1 auto;
}
